import React, { useEffect } from "react";
import { useState } from "react";
import ExpIcon from "../assets/expIcon.svg";
import PayIcon from "../assets/payIcon.svg";
import ClockIcon from "../assets/clockIcon.svg";
import LocIcon from "../assets/locIcon.svg";
import ModeIcon from "../assets/ModeIcon.svg";
import ArrowUp from "../assets/arrowUP.svg";
import ArrowDown from "../assets/arrowDown.svg";
import FileLogo from "../assets/fileLogo.svg";
import Flag from "../assets/flag.png";
import JobForm from "./careers/JobForm";
import axios from "axios";

function CareerComponent({
  data,
  showApplication,
  setShowApplication,
  i,
  showMore,
  toggleShowMore,
}) {
  const {
    jobName,
    experience,
    pay,
    type,
    location,
    mode,
    description,
    responsibilities,
    // sectionDetails,
  } = data;

  return (
    <div
      className="opportunities-container pointer"
      onClick={() => {
        toggleShowMore(i);
      }}
    >
      {/* <div> */}
      <div className="opportunity-items">
        <div className="opportunity-titles">{data.title}</div>
        <div className="job-arrow">
          <img src={showMore ? ArrowUp : ArrowDown} alt="" />
        </div>
      </div>
      {showMore === i && (
        <div>
          <div className="careers-exp">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={ExpIcon} alt="" />
              <span className="job-details">{data.experience} Years</span>
            </div>
            <div className="career-seperator" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={PayIcon} alt="" />
              <span className="job-details">{data.qualification}</span>
            </div>
            <div className="career-seperator" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={ClockIcon} alt="" />
              <span className="job-details">{data.jobType}</span>
            </div>
            <div className="career-seperator" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={LocIcon} alt="" />
              <span className="job-details">Chennai</span>
            </div>
            <div className="career-seperator" />
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={ModeIcon} alt="" />
              <span className="job-details">{data.natureOfJob}</span>
            </div>
            {/* <div className="career-seperator" /> */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <img src={PayIcon} alt="" />
              <span className="job-details">{data.qualification}</span>
            </div> */}
          </div>
          <div className="job-description">{data.description}</div>
          <div className="responsibility-title">Qualification</div>
          <li className="responsibility-list">{data.qualification}</li>
          <div className="responsibility-title">Responsibilities</div>
          <>
            {data.responsibilities.map((element, index) => {
              return (
                <li key={index} className="responsibility-list">
                  {element}
                </li>
              );
            })}
          </>{" "}
          <div className="responsibility-title">Key Responsibilities</div>
          <>
            {data.responsibilities.map((element, index) => {
              return (
                <li key={index} className="responsibility-list">
                  {element}
                </li>
              );
            })}
          </>
          <div className="responsibility-title">
            Required Skills and Qualifications
          </div>
          <>
            {data.requiredSkills.map((element, index) => {
              return (
                <li key={index} className="responsibility-list">
                  {element}
                </li>
              );
            })}
          </>
          <div className="responsibility-title">Preferred Skills</div>
          <>
            {data.preferredSkills.map((element, index) => {
              return (
                <li key={index} className="responsibility-list">
                  {element}
                </li>
              );
            })}
          </>
          <div className="responsibility-title">Required Tools Experience</div>
          <>
            {data.requiredTools.map((element, index) => {
              return (
                <li key={index} className="responsibility-list">
                  {element}
                </li>
              );
            })}
          </>
          <div
            className="career-apply"
            onClick={() => {
              setShowApplication(true);
            }}
          >
            Apply
          </div>
        </div>
      )}
      <div className="line-style" />
      {/* </div> */}
    </div>
  );
}

export default CareerComponent;
