import React, { useContext, useEffect, useRef, useState } from "react";
import "../styles/navbar.css";
import StaciaLogo from "../assets/Stacia Monogram.svg";
import StaciaLogoText from "../assets/Stacia logo.svg";
import five from "../assets/5yr logo.svg";
import ContactIcon from "../assets/ContactIcon.svg";
import { NavLink, Link } from "react-router-dom";
import droparrow from "../assets/droparrow.png";
import MobileNav from "../assets/MobileNav.png";
import ActiveStar from "../assets/ActiveStar.png";
import WhatsNew from "./WhatsNew";
import Modal from "react-modal";
import Contact from "./Contact";
import { useNavigate } from "react-router-dom";
import { useAnimation, motion } from "framer-motion";
import gsap from "gsap";
function NavBar() {
  const navigate = useNavigate();
  const [openWhatsNew, setOpenWhatsNew] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [logo, setLogo] = useState(StaciaLogo);
  const controls = useAnimation();
  const [scrollY, setScrollY] = useState(0);
  const animationStarted = useRef(false);
  const [text, setText] = useState("Innovating for you");
  const closeHandle = () => {
    setShowContact(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 0) {
      if (!animationStarted.current) {
        animationStarted.current = true;
        // Animate text to disappear from right to left
        controls.start("hidden");
      }
    } else {
      if (animationStarted.current) {
        animationStarted.current = false;
        // Animate text to appear from left to right
        controls.start("visible");
      }
    }
  }, [scrollY, controls]);

  const letterVariants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
      x: -20,
      transition: {
        duration: 0.5,
      },
    },
  };

  const containerVariants = {
    visible: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
    hidden: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };
  const flipVariants = {
    hidden: {
      rotateY: 90,
      opacity: 0,
      transition: { duration: 0.5 },
    },
    visible: {
      rotateY: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setLogo((prevLogo) => (prevLogo === StaciaLogo ? five : StaciaLogo));
      setText((prevText) =>
        prevText === "Innovating for you"
          ? `Celebrating 5th Anniversary`
          : "Innovating for you"
      );
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const [isOpenRes, setIsOpenRes] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown open/close state
  const toggleDropdown = () => {
    setIsOpenRes((prevState) => !prevState); // Toggle between open and closed
  };
  // console.log(isOpenRes);

  // Close the dropdown if a click is detected outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRes(false);
      }
    };

    // Add event listener when the component is mounted
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener when the component is unmounted
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const textRef = useRef(null);

  // useEffect(() => {
  //   const letters = textRef.current.querySelectorAll("span");

  //   // GSAP animation to fade in letters one by one with a 1-second delay
  //   gsap.fromTo(
  //     letters,
  //     { opacity: 0 },
  //     {
  //       opacity: 1,
  //       stagger: 0.1, // Adjusts the delay between each letter
  //       duration: 0.5, // Duration for each letter's fade-in
  //       delay: 0.5, // 1 second delay before the animation starts
  //     }
  //   );
  // }, [text]);

  useEffect(() => {
    const letters = textRef.current.querySelectorAll("span");

    // GSAP animation to fade in letters one by one with a 0.5-second delay
    gsap.fromTo(
      letters,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.1, // Adjusts the delay between each letter
        duration: 0.5, // Duration for each letter's fade-in
        // delay: 0.5, // 0.5-second delay before the animation starts
      }
    );

    // Set a timeout to animate the letters back to default after 4 seconds
    const timeout = setTimeout(() => {
      gsap.fromTo(
        letters,
        { opacity: 1 },
        {
          opacity: 0,
          stagger: -0.1, // Stagger to match the fade-in effect
          duration: 0.2, // Duration for each letter's fade-out
        }
      );
    }, 4200); // 4-second delay

    // Cleanup function to clear the timeout when the component is unmounted or dependencies change
    return () => clearTimeout(timeout);
  }, [text]);

  return (
    <div className="nav-container">
      <div className="nav-items-container">
        <div className="nav-left">
          <div className="mobile-nav">
            <img src={MobileNav} alt="" />
          </div>
          <Link
            to={"/"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            style={{
              marginRight: "2.5rem",
              position: "relative",
              display: "flex",
              alignItems: "center",
              columnGap: "0.75rem",
            }}
          >
            <motion.img
              key={logo} // Key changes to trigger animation
              src={logo}
              alt=""
              className="logo-rotate"
              style={{
                height: "3rem",
                width: "3rem",
                objectFit: "contain",
              }}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={flipVariants}
            />
            <div
              style={{
                marginBottom: "0.3rem",
              }}
            >
              <img
                src={StaciaLogoText}
                alt="Home"
                className="nav-logo"
                style={{ width: "90%", height: "100%", objectFit: "contain" }}
              />
              <div className="nav-logo-text" ref={textRef}>
                {text.split("").map((letter, i) => (
                  <span key={i}>{letter}</span> // Each letter wrapped in a span
                ))}
              </div>
              {/* <div className="nav-logo-text" ref={textRef}>
                {text.split("").map((letter, i) => {
                  if (letter === "5" && text.slice(i, i + 2) === "5t") {
                    // Render "5" followed by "th" in superscript
                    return (
                      <span key={i}>
                        5<sup>th</sup>
                      </span>
                    );
                  }
                  // Render individual letters as usual
                  return <span key={i}>{letter}</span>;
                })}
              </div> */}
            </div>
          </Link>
          <div style={{ display: "flex", alignItems: "center" }}>
            <NavLink
              to={"/services"}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="nav-items"
            >
              Services
            </NavLink>
            <NavLink
              to={"/products"}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="nav-items"
            >
              Products
            </NavLink>
            <NavLink
              to={"/project"}
              className="nav-items"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Projects
            </NavLink>
            <div className="dropdown" ref={dropdownRef}>
              <div className="dropdown-name" onClick={toggleDropdown}>
                Resource
              </div>
              {isOpenRes && (
                // <div className="dropdown-links">
                //   <NavLink
                //     to={"/article"}
                //     className="drop-items"
                //     onClick={() => {
                //       window.scrollTo(0, 0);
                //       setIsOpenRes(false); // Close dropdown on item click
                //     }}
                //   >
                //     Articles
                //   </NavLink>
                //   <NavLink
                //     to={"/case-study"}
                //     className="drop-items"
                //     onClick={() => {
                //       window.scrollTo(0, 0);
                //       setIsOpenRes(false); // Close dropdown on item click
                //     }}
                //   >
                //     Case studies
                //   </NavLink>
                // </div>
                <div className="res-drop-down-container">
                  <div
                    className="res-drop-down-container-item"
                    onClick={() => {
                      navigate("/article");
                      window.scrollTo(0, 0);
                      setIsOpenRes(false);
                    }}
                  >
                    Articles
                  </div>
                  <div
                    className="res-drop-down-container-item"
                    onClick={() => {
                      navigate("/case-study");
                      window.scrollTo(0, 0);
                      setIsOpenRes(false);
                    }}
                  >
                    Case Study
                  </div>
                </div>
              )}
            </div>

            {/* <div className="nav-items" style={{ opacity: 0.5 }}>
            Resources
          </div> */}
            <NavLink
              to={"/career"}
              className="nav-items"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Careers
            </NavLink>

            {/* <div
              className="mobile-whatsnew"
              style={{ marginLeft: "25px" }}
              onClick={() => setOpenWhatsNew(!openWhatsNew)}
            >
              {openWhatsNew ? (
                <div
                  style={{
                    color: "#fff",
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                >
                  What' New
                </div>
              ) : (
                <div
                  style={{
                    color: "rgba(255, 255, 255, 0.50)",
                    fontSize: "16px",
                    cursor: "pointer",
                    fontWeight: "500",
                  }}
                >
                  What' New
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="nav-right">
          {/* <Link to={"/community"} className="nav-item-whatsnew">
            Community
          </Link> */}
          {/* <div
            style={{
              height: "48px",
              width: "2px",
              backgroundColor: "#fff",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          ></div> */}
          <img
            src={ContactIcon}
            alt=""
            style={{ cursor: "pointer", width: "2rem", height: "2rem" }}
            onClick={() => setShowContact(true)}
          />
          {showContact && <Contact closeHandle={closeHandle} />}
          {/* <Modal
            isOpen={showContact}
            onRequestClose={() => setShowContact(false)}
            style={{
              overlay: {
                position: "fixed",
                zIndex: 1020,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#0D0225",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              content: {
                background: "white",
                width: "80%",
                maxHeight: "80%",
                padding: "6.25rem",
                position: "relative",
                borderRadius: "0.5rem",
              },
            }}
          ></Modal> */}
        </div>
      </div>
      {openWhatsNew ? <WhatsNew /> : ""}
    </div>
  );
}

export default NavBar;
