export const ProjectData = {
  Projects: [
    {
      id: 1,
      title: "Chilli solar house",
      Images: ["", "", "", "", "", ""],
      onelineDes: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      subheading: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
    },
    {
      id: 1,
      title: "TITLE2",
      type: "",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE3",
      type: "",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE4",
      type: "",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE5",
      type: "",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
  ],
  FeatureProject: [
    {
      id: 1,
      title: "TITLE6",
      type: "Featured",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE7",
      type: "Featured",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE8",
      type: "Featured",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE9",
      type: "Featured",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
    {
      id: 1,
      title: "TITLE10",
      type: "Featured",
      Image1: "",
      Image2: "",
      Image3: "",
      Image4: "",
      Image5: "",
      heading1: "Lorem ipsum dolor Lorem ipsum dolorLorem ipsum dolorLorem ",
      heading2:
        "Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem Lorem ipsum dolor Lorem ipsum dolorLorem",
      heading3: "Lorem ipsum dolor Lorem ipsum dolorLorem",
      para1:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. ",
      para2:
        "Lorem ipsum dolor sit amet consectetur.  Blandit in Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.  ",
      para3:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant.",
      heading4:
        "Lorem ipsum dolor Lorem ipsum dolorLoremLorem ipsum dolor Lorem ipsum dolorLoremLorem",
      heading4Discription:
        "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur. Blandit in Lorem ipsum dolor sit amet consectetur.",

      projectFeatures: [
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
        {
          id: "",
          serialNumber: "01",
          featureTitle: "Lorem ipsum dolor Lorem ipsum dolorLorem",
          featureDiscription:
            "Lorem ipsum dolor sit amet consectetur. Ullamcorper eu egestas tempor nunc nec habitant. Dolor vulputate tempor sagittis et maecenas praesent congue ac. Blandit in sagittis sem quis lectus aliquam. Lorem ipsum dolor sit amet consectetur.",
        },
      ],
      heading5: "",
      heading5Discription: "",
      Image6: "",
    },
  ],
};
