import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import "../styles/services.css";
import ServiceHeroImg from "../assets/serviceHeroImg.png";
import ServiceComponent from "../components/ServiceComponent";
import ServiceFeatureComponent from "../components/ServiceFeatureComponent";
import s1 from "../assets/s1.png";
import s2 from "../assets/s2.png";
import s3 from "../assets/s3.png";
import s4 from "../assets/s4.png";
import s5 from "../assets/s5.png";
import ServiceCard from "../components/Services/ServiceCard";
import AllServiceCard from "../components/Services/AllServiceCard";
import FeatureService from "../components/Services/FeatureService";
import MobileFooter from "../components/MobileFooter";
import SideBar from "../components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../redux/slice/serviceSlice";
import { Link } from "react-router-dom";
import LoadingStar from "../components/LoadingStar";
import Star from "../components/Star";

function ServicePage() {
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(true);
  const serviceData = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(fetchServices());
    // setLoading(serviceData.isLoading);
  }, []);

  const servData = serviceData.data.serviceBasedPosition;
  // console.log(servData);
  // console.log(serviceData.isLoading);

  return (
    <>
      <NavBar />
      <SideBar />
      {serviceData.isLoading ? (
        <div>
          <LoadingStar />
        </div>
      ) : (
        <>
          <div className="service-hero-container">
            {/* <div className="service-hero-section"> */}
            <div className="service-title">
              <span>Our Services</span>
              <Star />
            </div>
            <div className="service-section1-content-box">
              <div className="service-section-left">
                <div className="service-overview-title">Overview</div>
                <p>
                  Stacia Corp provides innovative, tailored solutions across
                  various industries, including advanced machinery, custom
                  software, and digital transformation. Our expert team delivers
                  scalable, high-quality services designed to enhance efficiency
                  and productivity, helping businesses stay competitive and
                  grow.
                </p>
              </div>
            </div>
            {/* </div> */}
          </div>

          <div className="all-services">
            {/* <AllServiceCard data={servData} /> */}
            <div className="all-service-box">
              {servData.map((data, i) => (
                <div className="service-card" key={i}>
                  <div className="service-card-img-box">
                    <img src={data.imageUrl} alt="" />
                  </div>
                  <div className="service-content-box">
                    <div className="feature-title">{data.title}</div>

                    <div className="feature-para" style={{ width: "100%" }}>
                      {data.des}
                    </div>
                    <Link
                      to={`/services/${data.title}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <div className="know-more" style={{ marginTop: "30px" }}>
                        Read more
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <Footer />
      <MobileFooter />
    </>
  );
}

export default ServicePage;
